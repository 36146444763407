import React from 'react';
import './App.css';
import EnvironmentBadge from './components/EnvironmentBadge/EnvironmentBadge';

function App() {
  return (
    <div className="App">
      <EnvironmentBadge />
      <header className="App-header">
        <img src="/lab_logo.png" className="App-logo" alt="logo" />
        <p>Lab</p>
      </header>
    </div>
  );
}

export default App;
